<template>
  <simple-modal>
    <div slot="header">{{ $t("RoomErrors.roomIsInaccessible") }}</div>
    <div v-if="statusMessage" slot="body">
      <div class="room-err-body">
        {{ statusMessage }}
      </div>
    </div>
    <div slot="footer" class="room-limit__footer">
      <ws-button lg color="primary" outlined @click="goToLoginAgain">
        <ws-icon xl light>window</ws-icon>
        {{ $t("Login.loginAgain") }}
      </ws-button>
    </div>
  </simple-modal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { LOGOUT } from "@/store/modules/auth/action-types";
import { landing, auth } from "@/store/modules/store.namespaces";
import { ROOM_LIMIT_MESSAGE_KEYS } from "@/constants/room/room-const";
import SimpleModal from "@/components/event/layouts/modals/SimpleModal";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";

export default {
  name: "RoomLimit",
  components: {
    SimpleModal,
    WsButton,
    WsIcon,
  },
  props: {
    status: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    ...mapState(landing, {
      landingTemplate: "template",
    }),
    statusMessage() {
      if (this.status < 0) {
        return "";
      }

      return this.$t(`RoomErrors.${ROOM_LIMIT_MESSAGE_KEYS[this.status]}`);
    },
  },
  methods: {
    ...mapActions(auth, {
      logout: LOGOUT,
    }),
    async goToLoginAgain() {
      const oauth2LandingExist =
        this.landingTemplate.login_form_type === "oauth2" && !this.landingTemplate.hide_landing;
      if (oauth2LandingExist) {
        await this.logout();
      } else {
        this.$router.replace("/lobby");
      }
    },
  },
};
</script>

<style scoped lang="less">
.room-limit {
  &__footer {
    padding: 0 25px 20px;
  }
}

.room-err-body {
  padding: 20px;
  text-align: center;
}
</style>
